@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');

html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    background: #fff; /* Updated gradient background */
    background-size: 200% 200%;
}


* {
    box-sizing: border-box;
}


h1,
h2 {
    color: #000;
}

h1 {
    font-size: 4.5rem;
    line-height: 4.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
}

h2 {
    font-size: 1.3rem;
    font-weight: normal;
    text-align: center;
    margin-top: 1rem;
}

.button-left,
.button-right {
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px 40px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.button-left {
    background-color: transparent;
    color: black;
}

.button-left:hover {
    background-color: black;
    color: white;
}

.button-right {
    background-color: black;
    color: white;
}

.button-right:hover {
    background-color: white;
    color: black;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.inspire-me{
    background-color: #cecece;
}

@media (max-width: 768px) {
    h1 {
        font-size: 3rem;
        line-height: 3.5rem;
    }

    .quick-guide .content,
    .inspire-me .content {
        width: 100%;
    }

    /* Apply a more prominent mic icon color change on mobile */
    .mic-recording {
        background-color: #f44336 !important;
        /* Red color for recording */
    }

    /* Animation adjustments for a more noticeable effect */
    .mic-recording.animate-pulse {
        animation: pulse 1s infinite ease-in-out;
        /* Faster and more noticeable pulse */
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 2.5rem;
        line-height: 3rem;
        margin-top: 10px;
    }

    .quick-guide .content,
    .inspire-me .content {
        width: 100%;
    }

    .button-left,
    .button-right {
        padding: 10px 30px;
    }
}

@media (min-width: 769px) {
    h1 {
        font-size: 4.5rem;
        line-height: 4.5rem;
    }

    .quick-guide .content,
    .inspire-me .content {
        width: 50%;
        margin: 0 auto;
    }
}

/* Fixed elements adjustments */
.quick-guide,
.inspire-me {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

/* Ensure no extra space is added */
.container {
    padding-bottom: 0;
}

/* Animation for recording state */
.animate-pulse {
    animation: pulse 2s infinite;
}

/* Animation for recording state */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.8;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}


.single_movie_div h3 {
    font-size: 1rem;
    line-height: 1.3rem;
}

/* In your global CSS or index.css */
.bg-red-500 {
    background-color: #f87171 !important;
}